<template>
  <ShopFilterMenue :isOwner="isOwner"/>
  <div>
    <div class="shop__content--info">
      <h1 class="shop__content--title">
        {{ shop.anzeigeName }}
        <span v-if="isOwner" class="shop__button--edit" @click="togglePopupEditProfile">{{ $t("shop_editProfile") }}</span>
      </h1>
      <p>{{  produktSeite.BeschreibungContent }}</p>
    </div>
    <div class="shop__content--products">
      <div v-if="isOwner" class="edit__shop--products">
        <NuxtLink :to="getRouteWithprefix('drafts')">
          <div class="shop__button--edit" @click="setProducts">{{$t("shop_adminProducts")}}</div>
        </NuxtLink>
      </div>
      <div class="team-products--wrapper" id="topanker">
        <NuxtLink class="team-products--item"
                     v-for="product in displayedProductsForShop"
                     :key="product.saveID"
                     :to="getRouteToShopItem(product.shopUUID)"
        >
          <div class="product-item--image" @click="setPaginationState" v-lazyload>
            <img width="331" height="350" :data-src="getPreviewImageURL(product.previewPath, product.saveID)"  :alt="imageAltText(product.nameImShop)" :title="imageAltText(product.nameImShop)" />
          </div>
          <div class="product-item--content" @click="setPaginationState">
            <h3 class="product-item--title">{{ product.nameImShop }}</h3>
            <span class="product-item--subtitle">{{ product.produkt }}</span>
            <!-- <span class="product-item--description">{{ product.description }}</span>-->
            <!--<span class="product-item--info">{{ product.featureStr }}</span>-->
            <span class="product-item--customize" v-if="product.isPersonalisierbar">{{ $t("shop_personalisierbar") }}</span>
            <span class="product-item--price">{{ $n(parseFloat(product.shopPrice), {key:'currency', currency:waehrung || 'eur'}) }}</span>
          </div>
        </NuxtLink>
      </div>
      <ClientOnly>
      <div class="product-item--pagination" v-if="allProductsForShop.length>15">

        <vue-awesome-paginate
            :total-items="allProductsForShop.length"
            v-model="currentPage"
            :items-per-page="perPage"
            :max-pages-shown="5"
            @click="onClickHandler"
        >
        </vue-awesome-paginate>

      </div>
      </ClientOnly>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, onMounted, ref, nextTick, type PropType, type ComputedRef} from "vue";
import {PopupNames, PopupStatus} from "~/components/PopupHandler";

import {useI18n} from "vue-i18n";

import {VueAwesomePaginate} from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import {getRouteWithprefix} from "~/_router/routeUtils";
import {type ShopSeite, useShopSeiten} from "~/store/shopSeitenStore";
import {useShopItems} from "~/store/shopItemsStore";
import {type ShopInfo, useShopInfos} from "~/store/shopInfosStore";
import {useFooterStore} from "~/store/FooterStore";
import {setHeadTags} from "~/metatags/metatags";
import {insertCloudflareDownscalingIntoURL} from "@k/global/globalUtils";
import {usePaginationStore} from "~/store/paginationStore";




export default {
  name: "ProductsOverview",
  components: {VueAwesomePaginate},
  props: {
    shop: Object as PropType<ShopInfo>,
    isOwner: Boolean
  },
  setup: async function() {
    const shopInfos = useShopInfos();
    const shopItems = useShopItems();
    const paginationStore = usePaginationStore();

    const i18n=useI18n();


    onMounted(()=> {
        currentPage.value = paginationStore.getCurrentPage;
        nextTick().then(() => {
          setTimeout(()=>{
            window.scrollTo(0, paginationStore.getScrollPosition);
          }, 100);
        });
    });

    watch (() => paginationStore.getCurrentPage, function(newValue, oldValue){
      currentPage.value = paginationStore.getCurrentPage;
    })

    const getPreviewImageURL=(previewPath:string, saveID:number)=>{
      return insertCloudflareDownscalingIntoURL(previewPath  + saveID.toString() + '_0.png', 350, 350);
    }
    const shopseiten = useShopSeiten();
    const shopInfo = useShopInfos();
    const footerstore = useFooterStore();
    const produktSeite = computed(()=>shopseiten.getProduktSeite) as ComputedRef<ShopSeite>;
    const perPage = ref(15);
    const currentPage = ref(1);
    const url = useRequestURL();
    setHeadTags(shopInfo.getShopSprache, shopInfo.getShopLand, shopInfo.shopname, shopseiten.getProduktSeite?.BeschreibungContent || "", shopInfo.shoplogopath, null, url.toString())


    const onClickHandler = () => {
      const section = document.getElementById("topanker");
      section.scrollIntoView({ behavior: 'smooth' });
      paginationStore.SET_CURRENT_PAGE(currentPage.value);
    };

    const displayedProductsForShop = computed(() => {
      const startIndex = (currentPage.value * perPage.value) - perPage.value;
      const endIndex = startIndex + perPage.value;
      return shopItems.filteredShopitems.shopitems.slice(startIndex, endIndex);
    });

    function setProducts() {
      shopItems.getAllPossibleProducts(shopInfo.shopname)
    }

    function togglePopupEditProfile() {
      PopupStatus.TOGGLE_POPUP(PopupNames.editProfil);
    }

    function imageAltText(produktname){
      return i18n.t("shop_Produktansicht") + ' ' + shopInfos.shopname + ' ' + produktname;
    }

    function setPaginationState(){
        const scrollPosition = window?.pageYOffset || 0;
        paginationStore.SET_CURRENT_PAGE(currentPage.value);
        paginationStore.SET_SCROLL_POSITION(scrollPosition);
    }



    return {
      produktSeite,
      setProducts,
      onClickHandler,
      togglePopupEditProfile,
      getRouteToShopItem: id => getRouteWithprefix(id),
      setPaginationState,
      getRouteWithprefix,
      imageAltText,
      waehrung: computed(() => shopInfos.getStandardWaehrung),
      currentPage,
      displayedProductsForShop,
      allProductsForShop:computed(()=>shopItems.filteredShopitems.shopitems),
      perPage,
      getPreviewImageURL
    }
  }
}
</script>

<style lang="scss" scoped>
.shop__content--info {
  margin-bottom: 50px;
  white-space: pre-wrap;
}

.shop__content--title {
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;

  .shop__button--edit {
    position: absolute;
    right: 0;
    top: -5px;

    @media (max-width: 740px) {
      position: relative;
      top: 0;
      right: auto;
      margin-top: 30px;
    }
  }
}

.shop__content--products {
  position: relative;
  width: 100%;

  .edit__shop--products{
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 740px) {
      justify-content: flex-start;
    }
  }
}

.team-products--wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 20px));
  grid-gap: 30px;
  margin-bottom: 30px;
  scroll-margin: 50px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, calc(50% - 20px));
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 100%);
  }
}

.team-products--item {
  margin-bottom: 10px;
}


.product-item--image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  /*border: 1px solid $color-grey;*/
  height: 280px;
  background: #ececec;

  img {
    max-height: 220px;
    object-fit: contain;
  }
}

.product-item--content {
  display: block;
  padding-top: 8px;


  .product-item--title {
    font-weight: 600;
    color: $color-black;
    font-size: 15px;
    line-height: 22px;
  }

  .product-item--subtitle {
    display: block;
    color: #818181;
    font-size: 15px;
    line-height: 22px;
  }

  .product-item--customize {
    font-size: 15px;
    line-height: 22px;
    color: $color-blue;
    display: block;
  }

  .product-item--price {
    font-weight: 600;
    padding-top: 10px;
    color: $color-black;
    font-size: 15px;
    line-height: 22px;
    display: block;
  }
}

:deep(.paginate-buttons) {
  display: block;
  border: none;
  cursor: pointer;
  color: $color-black;
  background: $color-white;
  padding: 6px 12px;
  margin-left: 1px;
  margin-right: 1px;
}

:deep(.paginate-buttons.active-page) {
  font-weight: bold;
  background: #ececec;
  border-radius: 100%;
}

:deep(.next-button) {
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMTYwIDExNS40IDE4MC43IDk2IDM1MiAyNTYgMTgwLjcgNDE2IDE2MCAzOTYuNyAzMTAuNSAyNTZ6IiBmaWxsPSIjMzAzMDMwIiBjbGFzcz0iZmlsbC0wMDAwMDAiPjwvcGF0aD48L3N2Zz4=");
  background-size: 24px auto;
  height: 40px;
  width: 40px;
  position: relative;
  top: -6px;
}

:deep(.back-button) {
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMTYwIDExNS40IDE4MC43IDk2IDM1MiAyNTYgMTgwLjcgNDE2IDE2MCAzOTYuNyAzMTAuNSAyNTZ6IiBmaWxsPSIjMzAzMDMwIiBjbGFzcz0iZmlsbC0wMDAwMDAiPjwvcGF0aD48L3N2Zz4=");
  background-size: 24px auto;
  height: 40px;
  width: 40px;
  position: relative;
  top: -6px;
  transform: scaleX(-1);
}

.product-item--pagination {
  text-align: center;
  margin-bottom: 40px;
}
</style>
