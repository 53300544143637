<template>
  <div class="shop-sidebar-filter">
    <div class="filter-block" v-for="(item, catIndex) in availableFilters" :key="catIndex">
      <span class="category-label" @click="toggleCategory(catIndex)">{{ getCatNameAndAnzFilter(catIndex) }}</span>
      <div v-if="activeCategoryIndex === catIndex"  class="category-submenu">
        <div v-for="(tag, tagIndex) in item.tags" :key="tagIndex" class="filter-tree-tag">
          <span @click="toggleFilter(catIndex, tagIndex)" :class="{ active: isInSelectedFilters(catIndex, tagIndex) }" class="filter-box"></span>
          <span @click="toggleFilter(catIndex, tagIndex)" class="filter-text">{{tag.name}}</span>
          <span>&nbsp;</span>
          <span class="filter-count" v-if="anzSelectedFilters>0">{{getAnzProdukte(catIndex, tagIndex)}}</span>
        </div>
      </div>
    </div>
    <button class="shop__button--edit"v-if="isOwner" @click="togglePopupEditFilter">{{ $t("shop_addFilter") }}</button>
  </div>
</template>

<script>
import {PopupNames, PopupStatus} from "~/components/PopupHandler";
import {useShopInfos} from "~/store/shopInfosStore";
import {useShopItems} from "~/store/shopItemsStore";
import {usePaginationStore} from "~/store/paginationStore";
import {useI18n} from "vue-i18n";
import { ref } from 'vue';

export default {
  name: "ShopFilterMenue",
  props: {
    isOwner: Boolean
  },
  setup(props) {
    const shopInfos = useShopInfos();
    const shopItems = useShopItems();
    const paginationStore = usePaginationStore();
    const i18n = useI18n();
    const filteredProducts = computed(()=> shopItems.filteredShopitems.shopitems);
    const activeCategoryIndex = ref(null);

    watch(()=>props.isOwner, (ownsIt) =>{
      if(ownsIt) {
        shopItems.getAvailableFilterTags(shopInfos.shopname);
      }
    })

    function removeDuplicates(items) {
      let map = new Map();

      items.forEach(item => {
        if (!map.has(item.name)) {
          map.set(item.name, item);
        }
      });

      return Array.from(map.values());
    }

    function togglePopupEditFilter() {
      PopupStatus.setProduct(null);
      PopupStatus.TOGGLE_POPUP(PopupNames.editFilter);
    }

    const availableFilters = computed(() => {
      return shopItems.allShopItems.shopitems.flatMap(item => item.tags)
          .reduce((resultArray, category) => {
            const existingCategory = resultArray.find(cat => cat.category === category.category);
            const uniqueTags = removeDuplicates(category.tags);

            if (!existingCategory) {
              resultArray.push({...category, tags: [...uniqueTags]}); // category.tags
            } else {
              category.tags.forEach(tag => {
                if (!existingCategory.tags.some(t => t.name === tag.name)) {
                  existingCategory.tags.push(tag);
                }
              });
            }
            return resultArray;
          }, []);
    });

    function selectFilter(catIndex, tagIndex){
      paginationStore.SET_CURRENT_PAGE(1);
      paginationStore.SET_SCROLL_POSITION(0);

      const selectedCategory = availableFilters.value[catIndex];
      const selectedTag = selectedCategory.tags[tagIndex];
      shopItems.addSelectedFilter({category: selectedCategory.category, tag: selectedTag.name});
    }

    function  removeFilter(catIndex, tagIndex){
      paginationStore.SET_CURRENT_PAGE(1);
      paginationStore.SET_SCROLL_POSITION(0);

      const selectedCategory = availableFilters.value[catIndex];
      const selectedTag = selectedCategory.tags[tagIndex];
      shopItems.removeSelectedFilter({category: selectedCategory.category, tag: selectedTag.name});
    }

    function toggleFilter(catIndex, tagIndex){
      if(isInSelectedFilters(catIndex, tagIndex)){
        removeFilter(catIndex, tagIndex);
      }
      else{
        selectFilter(catIndex, tagIndex);
      }
    }

    function isInSelectedFilters(catIndex, tagIndex) {
      const selectedCategory = availableFilters.value[catIndex];
      const selectedTag = selectedCategory.tags[tagIndex];

      if(shopItems.getSelectedFilters.filter(ele => ele.category === selectedCategory.category).length < 1){
        return false;
      }

      const selectedFilterCategory = shopItems.getSelectedFilters.filter(ele => ele.category===selectedCategory.category)[0];
      return selectedFilterCategory.tags.filter(ele => selectedTag.name === ele.tag).length > 0;
    }

    function getAnzProdukte(catIndex, tagIndex) {
      const selectedCategory = availableFilters.value[catIndex];
      const selectedTag = selectedCategory.tags[tagIndex];

      const anzItems = shopItems.filteredShopitems.shopitems.filter(item => {
                            const itemCategoryGroup = item.tags.find(categoryGroup => categoryGroup.category === selectedCategory.category);
                            if (!itemCategoryGroup) {
                              return false;
                            }
                            return itemCategoryGroup.tags.some(tag => selectedTag.name === tag.name);
                      }).length;

      if((shopItems.getSelectedFilters.filter(ele => ele.category === selectedCategory.category).length < 1) || anzItems > 0){
        return "("+anzItems.toString()+")";
      }
      else{
        return "";
      }
    }

    function getAnzFilter(selectedCategory){
      if(shopItems.getSelectedFilters.filter(ele => ele.category === selectedCategory.category).length < 1){
        return 0;
      }
      const selectedFilterCategory = shopItems.getSelectedFilters.filter(ele => ele.category===selectedCategory.category)[0];
      return selectedFilterCategory.tags.length;
    }

    function getCatNameAndAnzFilter(catIndex){
      const selectedCategory = availableFilters.value[catIndex];
      const anzSelectedFilterInCategory = getAnzFilter(selectedCategory);

      if(anzSelectedFilterInCategory > 0){
        return selectedCategory.category + " (" + anzSelectedFilterInCategory.toString() + ")";
      }
      else{
        return selectedCategory.category
      }
    }

    const toggleCategory = (index) => {
      // Wenn die Kategorie bereits aktiv ist, schließen
      activeCategoryIndex.value = activeCategoryIndex.value === index ? null : index;
    };

    return {
      togglePopupEditFilter,
      selectFilter,
      removeFilter,
      toggleFilter,
      isInSelectedFilters,
      anzSelectedFilters: computed(() => shopItems.getSelectedFilters.length),
      getAnzProdukte,
      availableFilters,  // alle Filter die noch verfügbar sind in der aktuellen auswahl
      filteredProducts,  //alle Produkte die die ausgewählten Filter erfüllen
      activeCategoryIndex,
      toggleCategory,
      getCatNameAndAnzFilter
    }
  }
}
</script>

<style lang="scss" scoped>
.shop-sidebar-filter {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;

  .shop__button--edit {
    margin-top: 30px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iS29tcG9uZW50ZV80OV8xIiBkYXRhLW5hbWU9IktvbXBvbmVudGUgNDkg4oCTIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE0IiBoZWlnaHQ9IjE0IiB2aWV3Qm94PSIwIDAgMTQgMTQiPg0KICA8cGF0aCBpZD0iUGZhZF8yODYiIGRhdGEtbmFtZT0iUGZhZCAyODYiIGQ9Ik00ODMuMDM1LDI1MS45MzdINDc3LjFWMjQ2aC0yLjEyN3Y1LjkzN2gtNS45Mzd2Mi4xMjZoNS45MzdWMjYwSDQ3Ny4xdi01LjkzN2g1LjkzN1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NjkuMDM1IC0yNDYpIiBmaWxsPSIjMjQyNDI0Ii8+DQo8L3N2Zz4NCg==');
    padding-left: 40px;
    background-size: 10px;
  }
}

.filter-block {
  position: relative;
}

.category-label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 6px;
  padding: 8px 18px 8px 12px;
  width: 140px;
  margin-right: 20px;
  border: 1px solid $color-grey-bg;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  &:after {
    content: '▼';
    position: absolute;
    right: 12px;
    top: 9px;
    font-size: 11px;
  }
}

.filter-tree-tag {
  display: flex;
  margin-bottom: 8px;

  .filter-box {
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid #333;
    margin-right: 8px;
    cursor: pointer;
    position: relative;

    &.active {
      /*background: $color-blue;*/
      &:after {
        content: 'x';
        font-size: 16px;
        top: -1px;
        left: 1px;
        position: absolute;
        width: 16px;
        height: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .filter-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 15px;
    cursor: pointer;
  }
}

.category-submenu {
  position: absolute;
  background-color: $color-white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px 20px 40px 20px;
  z-index: 99;
  top: 34px;
  width: 200px;
}
</style>